@layer components {
	.nav-placeholder {
		@apply h-nav w-full;
	}

	.nav {
		@apply h-nav w-full flex items-center justify-center bg-nav fixed top-0 z-20;
	}

	.nav__top {
		@apply border-t-2 border-primary w-full absolute top-0;
	}

	.nav__inner {
		@apply w-page max-w-full px-4 flex flex-row items-center justify-between;
	}

	.nav__logo {
		@apply mobile:order-2 mobile:grow mobile:pl-6 pr-6 desktop:border-r border-grey-tone-three text-2xl;
	}

	.nav__logo img {
		@apply max-h-[24px];
	}

	.nav__main {
		@apply mobile:order-1 flex desktop:grow items-center;
	}

	.nav__toggle {
		@apply flex gap-2 desktop:pl-5 items-center font-primary text-base text-text-color-one uppercase cursor-pointer;
	}

	.nav__toggle i {
		@apply text-2xl;
	}

	.nav__back {
		@apply flex items-center font-medium gap-1;
	}

	.nav__subs-toggle {
		@apply desktop:hidden w-1/3 flex justify-end items-center text-base pr-4;
	}

	.nav__subs {
		@apply desktop:absolute mobile:fixed mobile:z-10 mobile:border-t mobile:border-grey-tone-three flex flex-col gap-1 p-4 left-0 desktop:top-full mobile:top-nav mobile:bottom-0 mobile:right-0 desktop:border-t-2 desktop:border-primary bg-nav desktop:w-[364px];
	}

	.nav__sub {
		@apply mobile:order-2 mobile:text-sm text-base font-medium bg-grey-tone-one hover:bg-grey-tone-three desktop:px-4 mobile:px-3 min-h-[48px] flex items-center;
	}

	.nav__items-top,
	.nav__subs-top {
		@apply desktop:hidden order-1 pb-3 flex flex-row justify-between font-bold text-sm font-secondary text-text-color-one;
	}

	.nav__items {
		@apply mobile:fixed mobile:top-nav mobile:left-0 mobile:right-0 mobile:bottom-0 mobile:bg-nav mobile:flex-col mobile:gap-1 mobile:p-4 mobile:border-t border-grey-tone-three flex;
	}

	.nav__item {
		@apply mobile:order-2 mobile:flex mobile:justify-between mobile:bg-primary relative font-primary mobile:text-sm text-base text-text-color-one font-medium cursor-pointer hover:bg-primary;
	}

	.nav__link {
		@apply mobile:grow flex items-center gap-2 desktop:py-5 mobile:min-h-[48px] px-6 mobile:justify-between;
	}

	.nav__link i {
		@apply mobile:hidden;
	}

	.nav__item:hover .nav__subs, .nav__item:active .nav__subs {
		@apply desktop:flex;
	}

	.nav__shop {
		@apply order-3 flex;
	}

	.nav__shop-item {
		@apply relative last:pr-[17px] py-4 cursor-pointer text-text-color-one;
	}

	.nav__shop-item i {
		@apply text-2xl;
	}

	.nav__count {
		@apply absolute right-0 bottom-1/2 translate-y-1/2 text-sm font-bold rounded-full bg-primary flex items-center justify-center min-w-[24px] h-[24px] shrink-0 text-xs;
	}

	.nav__open,
	.nav__close {
		@apply mobile:w-[21px];
	}

	.nav__popup {
		@apply fixed top-0 left-0 right-0 bottom-0 flex;
	}

	.nav__popup-inner {
		@apply w-[364px] bg-nav p-4 flex flex-col;
	}

	.nav__popup-top {
		@apply text-text-color-one font-primary font-bold text-xl border-b border-grey-tone-three flex justify-between items-center pt-4 pb-10 px-4 mb-8;
	}

	.nav__popup-close {
		@apply h-[44px] w-[44px] border border-primary text-base text-text-color-one hover:bg-primary flex items-center justify-center cursor-pointer;
	}

	.nav__popup-close i {
		@apply text-base;
	}

	.nav__popup-items-top {
		@apply pb-3 flex flex-row justify-between font-bold text-sm font-secondary text-text-color-one;
	}

	.nav__popup-items {
		@apply flex flex-col gap-1 overflow-y-auto;
	}

	.nav__dropdown::part(base) {
		@apply border-0;
	}

	.nav__dropdown::part(header) {
		@apply bg-primary hover:bg-secondary font-primary text-sm font-medium text-text-color-one p-0 px-3 min-h-[48px];
	}

	.nav__dropdown::part(summary-icon) {
		@apply text-base text-text-color-one;
	}

	.nav__dropdown::part(content) {
		@apply flex flex-col gap-1 p-0 pt-1;
	}

	.nav__popup-overlay {
		@apply h-full grow bg-black opacity-20;
	}

	.nav__dropdown-item {
		@apply flex items-center gap-3 min-h-[48px] px-3 justify-between bg-primary hover:bg-secondary font-primary text-sm font-medium text-text-color-one;
	}

	.nav__dropdown-sub-item {
		@apply flex items-center gap-3 min-h-[48px] px-3 justify-between bg-grey-tone-one hover:bg-grey-tone-three font-primary text-sm font-medium text-text-color-one;
	}
}
