@layer components {
	/* Default */
	.block-text {
		@apply w-full flex items-center justify-center py-20 text-text-color-one;
	}

	.block-text__inner {
		@apply w-page max-w-full;
	}

	.block-text__content {
		@apply flex gap-4 flex-col;
	}

	.block-text__title {
		@apply text-2xl font-bold font-primary text-text-color-one;
	}

	.block-text__text {
		@apply text-sm font-secondary text-inherit;
	}

	.block-text__text p.intro {
		@apply font-bold text-lg italic;
	}

	.block-text a {
		@apply underline;
	}

	.block-text ol {
		@apply list-inside list-disc;
	}

	.block-text ul {
		@apply list-inside list-decimal;
	}

	.block-text__text h1 {
		@apply text-6xl font-primary pb-4;
	}

	.block-text__text h2 {
		@apply text-5xl font-primary pb-4;	
	}

	.block-text__text h3 {
		@apply text-4xl font-primary pb-4;	
	}

	.block-text__text h4 {
		@apply text-3xl font-primary pb-4;	
	}

	.block-text__text h5 {
		@apply text-2xl font-primary pb-4;	
	}

	.block-text__text h6 {
		@apply text-xl font-primary pb-4;	
	}

	.block-text__text h1,
	.block-text__text h2,
	.block-text__text h3,
	.block-text__text h4,
	.block-text__text h5,
	.block-text__text h6 {
		@apply text-text-color-one;
	}

	/*quote*/
	.block-text__text blockquote, .block-text__text q {
		@apply text-base text-center py-[80px] mobile:py-[115px] px-[115px] mobile:px-8 relative block my-8 bg-white text-text-color-one;
	}

	.block-text__text blockquote:before, .block-text__text q:before {
		@apply absolute top-0 left-0 w-[80px] h-[80px] block content-['"'] font-primary text-6xl leading-[110px];
	}

	.block-text__text blockquote:after, .block-text__text q:after {
		@apply absolute bottom-0 right-0 w-[80px] h-[80px] block content-['"'] font-primary text-6xl leading-[110px];
	}
	/*quote colors*/
	.block-text--section-bg-five .block-text__text blockquote,
	.block-text--section-bg-five .block-text__text q,
	.block-text--section-bg-white .block-text__text blockquote,
	.block-text--section-bg-white .block-text__text q {
		@apply bg-primary text-text-color-one;
	}

	.block-text--section-bg-five .block-text__text blockquote:before,
	.block-text--section-bg-five .block-text__text q:before,
	.block-text--section-bg-white .block-text__text blockquote:before,
	.block-text--section-bg-white .block-text__text q:before {
		@apply bg-white text-primary;
	}

	.block-text--section-bg-five .block-text__text blockquote:after,
	.block-text--section-bg-five .block-text__text q:after,
	.block-text--section-bg-white .block-text__text blockquote:after,
	.block-text--section-bg-white .block-text__text q:after {
		@apply bg-white text-primary;
	}

	/* Text align */
	.block-text--center {
		@apply text-center;
	}

	.block-text--right {
		@apply text-right;
	}

	.block-text--justify {
		@apply text-justify;
	}

	/* Theme colors */
	
	.block-text--section-bg-two,
	.block-text--section-bg-five,
	.block-text--section-bg-white {
		@apply text-text-color-three;
	}

	/* Columns */
	.block-text--columns-two .block-text__text {
		@apply desktop:columns-2 desktop:gap-20;
	}

	.block-text--columns-two .block-text__title {
		@apply text-center;
	}
}
