@layer components {
	/* Default */
	.block-embed {
		@apply w-full flex items-center justify-center py-20 text-text-color-one;
	}

	.block-embed__inner {
		@apply w-page max-w-full flex flex-col gap-8 py-20 px-[8%] mobile:p-8;
	}

	.block-embed__iframe {
		@apply w-full flex flex-col justify-center;
	}

	.block-embed__content {
		@apply flex gap-4 flex-col;
	}

	.block-embed__title {
		@apply text-2xl font-bold font-primary text-text-color-one;
	}

	.block-embed__text {
		@apply text-sm font-secondary text-inherit;
	}

	/* Text align */
	.block-embed--center {
		@apply text-center;
	}

	.block-embed--right {
		@apply text-right;
	}

	.block-embed--justify {
		@apply text-justify;
	}

	/* Theme colors */
	
	.block-embed--section-bg-two,
	.block-embed--section-bg-five {
		@apply text-text-color-three;
	}

	.block-embed--section-bg-white .block-embed__inner {
		@apply bg-primary;
	}
}
