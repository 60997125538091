@layer components {
	.section {
		@apply w-full;
		word-break: break-word;
	}

	.section__inner {
		@apply flex flex-col justify-center items-center w-full;
	}

	.section__inner--section-bg-one {
		@apply bg-section-bg-one;
	}

	.section__inner--section-bg-two {
		@apply bg-section-bg-two;
	}

	.section__inner--section-bg-three {
		@apply bg-section-bg-three;
	}

	.section__inner--section-bg-four {
		@apply bg-section-bg-four;
	}

	.section__inner--section-bg-five {
		@apply bg-section-bg-five;
	}

	.section__inner--background-white {
		@apply bg-section-bg-white;
	}

	.section__row {
		@apply flex mobile:flex-col desktop:flex-row items-stretch justify-center w-page max-w-full flex-wrap;
	}

	.section__col {
		@apply flex flex-col gap-4 px-4 mobile:px-8;
	}
}
